<template>
  <div class="introduce">

 
<!-- 
    <h1 class="index-h2">启迪春雨</h1>

    <el-row>
      <el-col class="col"
              :xs="24"
              :md="12">
 
         <h2 class="">下载APP</h2>
        <div class="hr"></div>
         <p>
         扫描右侧二维码下载启迪春雨APP，进入应用前，你需先同意<a href="http://api.panda-music.cn/app_service.html">《服务协议》</a>和<a href="http://api.panda-music.cn/app_privacy.html">《隐私政策》</a>，否则将退出应用。
         </p>
         
        
      </el-col>


      <el-col class="col" :xs="24"
              :md="12">

        <div class="  hvr-grow">
          <el-image :src=" require('@/assets/qrapp.png')"
                    
                    class="img-box3 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>

      </el-col>

    </el-row> -->


 <h1 class="index-h2">关于启迪春雨</h1>

    <el-row>
      <el-col class="col"
              :xs="24"
              :md="12">

        <h2 class="">主要功能</h2>
        
        <div class="hr"></div>
         
        <p>    
为机构用户和注册用户提供在线学习、在线练习、商城购物、周末约课、考级报名、新闻资讯等多个功能板块。通过先进的管理理念、专业的乐理知识与技术相结合，实现智能陪练，不断引导孩子科学练习。
        </p>
        <br/>
         <h2 class="">软件特点</h2>
        
        <div class="hr"></div>
         
        <p>    

用户可以根据自己的喜好，选择自己喜欢的曲目来进行练习。
每一首乐曲，都由专业老师视频示范标准演奏手型和指法，跟着老师唱歌熟悉乐曲，再看着视频调整坐姿、手型，快速掌握标准手型，学琴、学鼓更轻松。
</p> 
         <p>
    采用拆分式教学法把每个考级内容进行拆分讲解，分步骤学习和解决难点。
拆分式教学方法大幅度地减轻了学生的思想负担和精神负担，加深强化了学习内容，达到在轻松愉悦的气氛中，掌握各项乐器演奏的基本功以及难以理解的乐理知识。
让每一个孩子，在享受音乐中真正学懂钢琴和架子鼓，在学懂钢琴和架子鼓中走进音乐，轻松考级。
</p>
        
      </el-col>


      <el-col :xs="24"
              :md="12">

        <div class="  hvr-grow">
          <el-image :src=" require('@/assets/ee60.jpg')"
                    
                    class="img-box ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>

      </el-col>

    </el-row>
  
    

 <h1 class="index-h2">课程体系</h1>
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src=" require('@/assets/ee61.jpg')"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>

 <h1 class="index-h2">丰富的乐理知识和名师示范</h1>
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src=" require('@/assets/ee62.jpg')"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>


    </el-row>

   

  </div>
</template>


<script>
export default {
  data () {
    return {
      url: require('@/assets/ee20-1.jpg'),
       url2: require('@/assets/ee20.jpg')

    }
  },
  methods: {

  },
  metaInfo: {
    title: '北京启迪邦妙文体科技有限公司-启迪春雨', 
    meta: [{                 
      name: '启迪邦妙,妙音乐教室,智能音乐教室,启迪春雨,启迪智慧教育,智能钢琴,钢琴陪练,钢琴考级,智能架子鼓,腰旗橄榄球',
      content: '北京启迪邦妙文体科技有限公司长年致力于青少年素质教育智能化平台建设工作，拥有多项智能教育领域专利、软件著作权等产品成果，是启迪控股集团内专业从事青少年素质教育服务的控股子公司。'
    }]      
  } 
  //   components: {

  //   },
}
</script>

<style lang="less" scoped>
.hvr-grow{
  display: flex;
  align-items: center;
  justify-content:center;
  line-height: 35px;
}
.introduce {
  padding-bottom: 100px;
}
.el-row {
  display: flex;
  align-items: center;
}
.col {
  text-align: left;
   
  h3 {
    margin-bottom: 5px;
  }
  .hr {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 2px;
    background-color: whitesmoke;
  }
  .msg {
    font-size: 14px;
  }

  p {
    font-size: 16px;
    line-height: 35px;
  }

  .img-box{
    width: 300px;
  }
  .img-box2{
    width: 1200px;
  }

   .img-box3{
    width: 200px;
    text-align: center;
  }
}
</style>
<style lang="less" >
</style>